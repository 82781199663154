exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dagcoinwallet-index-js": () => import("./../../../src/pages/dagcoinwallet/index.js" /* webpackChunkName: "component---src-pages-dagcoinwallet-index-js" */),
  "component---src-pages-ecosystemtimeline-index-js": () => import("./../../../src/pages/ecosystemtimeline/index.js" /* webpackChunkName: "component---src-pages-ecosystemtimeline-index-js" */),
  "component---src-pages-page-js": () => import("./../../../src/pages/page.js" /* webpackChunkName: "component---src-pages-page-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-regulartimeline-index-js": () => import("./../../../src/pages/regulartimeline/index.js" /* webpackChunkName: "component---src-pages-regulartimeline-index-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-distribution-js": () => import("./../../../src/templates/distribution.js" /* webpackChunkName: "component---src-templates-distribution-js" */),
  "component---src-templates-ecosystem-js": () => import("./../../../src/templates/ecosystem.js" /* webpackChunkName: "component---src-templates-ecosystem-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-privacypolicy-js": () => import("./../../../src/templates/privacypolicy.js" /* webpackChunkName: "component---src-templates-privacypolicy-js" */),
  "component---src-templates-singleblog-js": () => import("./../../../src/templates/singleblog.js" /* webpackChunkName: "component---src-templates-singleblog-js" */),
  "component---src-templates-sponsorships-js": () => import("./../../../src/templates/sponsorships.js" /* webpackChunkName: "component---src-templates-sponsorships-js" */),
  "component---src-templates-sustainability-js": () => import("./../../../src/templates/sustainability.js" /* webpackChunkName: "component---src-templates-sustainability-js" */),
  "component---src-templates-tos-js": () => import("./../../../src/templates/tos.js" /* webpackChunkName: "component---src-templates-tos-js" */)
}

