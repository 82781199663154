module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"dagcoin","accessToken":"MC5YdDRoZHhBQUFDSUFTX01x.YTNr77-9JQkw77-977-9CRfvv70qSG7vv73vv73vv73vv71077-977-977-977-977-977-9fkN4e--_ve-_vQ","promptForAccessToken":true,"apiEndpoint":"https://dagcoin.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"www.dagcoin.org","short_name":"Dagcoin","start_url":"/","background_color":"#182af5","theme_color":"#182af5","display":"minimal-ui","icon":"src/images/gatsby-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8d89bb8fae2326549fc246cfc3d3bb90"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
