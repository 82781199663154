import React from "react";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import PropTypes from "prop-types";

export const Seo = ({ children, description, image, title, pathname }) => {
  const {
    description: defaultDescription,
    image: defaultImagePath,
    siteUrl,
    title: defaultTitle,
  } = useSiteMetadata();
  // TODO: Would need to add the `lang` props back. As the support for editing <html> and <body> was added in gatsby@5.5.0.
  // Reference: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/#editing-html-and-body
  // It's okay if it's missing no. Since we don't use double languages
  // E.g. <html lang={lang}>
  const seo = {
    description: description || defaultDescription,
    image: image || `${siteUrl}/${defaultImagePath}`,
    siteUrl: `${siteUrl}/${pathname || ""}`,
    title: title || defaultTitle,
  };

  return (
    <>
      {!!title ? (
        <title>{`${title} | Dagcoin`}</title>
      ) : (
        <title>{seo.title}</title>
      )}
      <meta name="description" content={seo.description} />
      <meta name="og:description" content={seo.description} />
      <meta name="og:image" content={seo.image} />
      <meta name="og:title" content={title} />
      <meta name="og:type" content="Website" />
      <meta name="twitter:card" content="Summary" />
      <meta name="twitter:creator" content="Dagcoin" />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:title" content={title} />
      <meta name="url" content={seo.siteUrl} />
      {children}
    </>
  );
};

Seo.propTypes = {
  children: PropTypes.node,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  title: PropTypes.string,
};
